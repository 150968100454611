import { Component, createMemo, ErrorBoundary, Suspense } from "solid-js";
import { revalidate, useNavigate } from "@solidjs/router";
import { Motion } from "solid-motionone";
import { SelectBox } from "~/components/inputs";
import { type AccessorWithLatest } from "@solidjs/router";
import { type ListCartResponse } from "~/services/roma-api/cart";
import { useAction, useSubmission } from "@solidjs/router";
import { retrieveCart, updateCurrentCartAction } from "~/services/cart";
import { useErrorContext, useSessionContext } from "~/utils/contexts";
import { checkError } from "~/services/roma-api/checkError";

type CartDropdownProps = {
  carts: AccessorWithLatest<ListCartResponse>;
};

export const CartDropdown: Component<CartDropdownProps> = (props) => {
  const { session } = useSessionContext();
  const { addError } = useErrorContext();
  const navigate = useNavigate();

  const updateCart = useAction(updateCurrentCartAction);
  const updatingCart = useSubmission(updateCurrentCartAction);

  const cartOptions = createMemo(() => {
    if (!props.carts) return [];
    return props.carts().Results?.map((cart) => ({
      label: cart.Name,
      value: cart.ID,
    }));
  });

  const handleUpdateCurrentCart = async (id: string) => {
    try {
      //? switching the order here (nav first then update) resolved an issue of double fetching the cart
      navigate(`/checkout/${id}`);
      await updateCart(id);
      // revalidate(retrieveCart.keyFor(id))
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("handleUpdateCurrentCart caught error >> ", err);
      }
      addError(err, {
        severity: "critical",
        // autoDisappear: true,
        title: "Cart Update Error",
        message:
          "An error occurred when trying to switch your current cart. Try again shortly, and if the issue persits please contact support.",
        showDetails: true,
      });
      // throw error; // why is this error not caught by EB? or surrounding EB?
    }
  };

  return (
    <>
      <ErrorBoundary
        fallback={(err, reset) => {
          // TODO - Error-Boundary
          console.log("error caught in EB");
          return <div>An error occurred</div>;
        }}
      >
        <Motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "50px", opacity: 1 }}
          exit={{
            height: 0,
            opacity: 0,
            transition: { duration: 0.3 },
          }}
          transition={{ duration: 0.5 }}
          class="w-full"
        >
          <Suspense fallback="BOOP">
            <SelectBox
              options={cartOptions()}
              loading={updatingCart.pending}
              onChange={async (option) => {
                // await updateCart(option.value as string)
                // navigate(`/checkout/${option.value}`);
                await handleUpdateCurrentCart(option.value as string);
              }}
              value={{ value: session()?.cart_id }}
              placeholder="Select a cart"
              triggerClass="rounded-sm"
            />
          </Suspense>
        </Motion.div>
      </ErrorBoundary>
    </>
  );
};
