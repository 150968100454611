import { createSignal } from "solid-js";
import { useCheckoutContext } from "~/utils/contexts";
import { TextFieldInput } from "~/components/inputs";

export const PONumber = () => {
  const { checkoutData, setCheckoutData } = useCheckoutContext();
  const [POError, setPOError] = createSignal<boolean>();

  const validateInput = (val: string) => {
    // Allow alphanumeric, spaces, hyphens only
    const pattern = /^[a-zA-Z0-9 -]{0,30}$/;
    return pattern.test(val);
  };

  return (
    <TextFieldInput
      name="po-number"
      type="text"
      placeholder="Optional (30 character max)"
      value={checkoutData.PONumber}
      onChange={(val) => {
        setPOError(false);
        validateInput(val)
          ? setCheckoutData("PONumber", val)
          : setPOError(true);
      }}
      validationState={POError() ? "invalid" : "valid"}
      error="Letters, numbers, and hyphens only, 30 character max."
      maxLength={30}
    />
  );
};
