import { FormattedCart } from "~/routes/(layout)/checkout/[cart_id]";
import { getBulkInventory } from "~/services/products";
import { PT } from "~/utils/products";
import { PlantCodeKeys } from "~/services/roma-api/products/types";
import { stockThreshold, ThresholdCheckout } from "~/utils/threshold";

/**
 * Helper function that loops through all SKUs in a cart and checks inventory levels.
 * Excludes checks on galleryFrame, photoFrame, & cornerSample types.
 * Flags the line item (RequiresBackorderSelection) and the cart (ProductRequiresBackorder) when a low stock item is found.
 * @param cart
 * @returns
 */
export const verifyInventoryLevels = async (
  cart: FormattedCart
): Promise<FormattedCart> => {
  if (!cart?.Lines || cart?.LineCount <= 0) {
    return cart;
  }

  // Extract all SKUs from cart lines
  const skus = cart?.Lines?.flatMap((line) =>
    line.SubItems.map((sub) => sub.Moulding)
  );

  // Get inventory levels for all SKUs
  const inv = await getBulkInventory(skus);

  // Filter SKUs with stock <= 100 at any plant
  const skusUnder100 = Object.fromEntries(
    Object.entries(inv).filter(([sku, plants]) =>
      Object.values(plants).some((qty) => qty <= 100)
    )
  );

  // Update cart with low stock SKUs
  const updatedCart = { ...cart, LowStockSkus: skusUnder100 };

  // Check each line item for backorder requirements
  for (const line of updatedCart.Lines) {
    // Skip GF/PF/CS
    if ([PT.GALLERYFRAME, PT.PHOTOFRAME, PT.CORNERSAMPLE].includes(line.Type)) {
      continue;
    }

    for (const sub of line.SubItems) {
      const isLowStock = Object.keys(skusUnder100).includes(sub.Moulding);
      if (!isLowStock) continue;

      const plantCode =
        line.Plant === ""
          ? (updatedCart.DefaultPlant as PlantCodeKeys)
          : (line.Plant as PlantCodeKeys);

      const stockLevel = inv[sub.Moulding][plantCode];
      const category = updatedCart.Products[sub.Moulding].Category;

      const threshold = stockThreshold(stockLevel, category).checkout;
      const requiresBackorder = [
        ThresholdCheckout.backorder,
        ThresholdCheckout.optionalBackorder,
      ].includes(threshold);

      if (requiresBackorder && !line.BackOrder && !line.CancelBackOrder) {
        line.RequiresBackorderSelection = true;
        updatedCart.ProductRequiresBackorder = true;
      }
    }
  }

  updatedCart.StockVerificationComplete = true;
  return updatedCart;
};
