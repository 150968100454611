import { FormattedCartLines } from "./groupCartLines";
import { PT } from "~/utils/products";

export const MIN_ORDER_AMOUNTS = {
  [PT.PHOTOFRAME]: 200,
  [PT.GALLERYFRAME]: 500,
} as const;

export interface MinAmountValidation {
  valid: boolean;
  minAmount: number;
  difference: number;
  name: string;
}

/**
 * Checks GF/PF orders in cart, flags when min purchase amount is not met.
 * @param lines (Cart as formatted by groupCartLines())
 * @returns 
 */
export const validateMinAmount = (
  lines: FormattedCartLines | undefined
): MinAmountValidation => {
  // Default 
  const defaultValidation: MinAmountValidation = {
    valid: true,
    minAmount: 0,
    difference: 0,
    name: "",
  };

  if (!lines?.photoFrame || !lines?.galleryFrame) {
    return defaultValidation;
  }

  if (!lines.photoFrame.isInCart && !lines.galleryFrame.isInCart) {
    return defaultValidation;
  }

  // Check GF
  if (
    lines.galleryFrame.isInCart &&
    lines.galleryFrame.cumTotal < MIN_ORDER_AMOUNTS[PT.GALLERYFRAME]
  ) {
    return {
      valid: false,
      minAmount: MIN_ORDER_AMOUNTS[PT.GALLERYFRAME],
      difference: MIN_ORDER_AMOUNTS[PT.GALLERYFRAME] - lines.galleryFrame.cumTotal,
      name: "Gallery Frames",
    };
  }

  // Check PF
  if (
    lines.photoFrame.isInCart &&
    lines.photoFrame.cumTotal < MIN_ORDER_AMOUNTS[PT.PHOTOFRAME]
  ) {
    return {
      valid: false,
      minAmount: MIN_ORDER_AMOUNTS[PT.PHOTOFRAME],
      difference: MIN_ORDER_AMOUNTS[PT.PHOTOFRAME] - lines.photoFrame.cumTotal,
      name: "Photo Frames",
    };
  }

  return defaultValidation;
};
