import { PT } from "~/utils/products";
import { CartDetail } from "~/services/roma-api/cart/types";

export type FormattedCartLines = {
  products: any[];
  samples: any[];
  photoFrame: {
    isInCart: boolean;
    cumTotal: number;
  };
  galleryFrame: {
    isInCart: boolean;
    cumTotal: number;
  };
};

export const defaultFormattedCartLines: FormattedCartLines = {
  products: [],
  samples: [],
  photoFrame: { isInCart: false, cumTotal: 0 },
  galleryFrame: { isInCart: false, cumTotal: 0 },
};

export const groupCartLines = (
  cart: CartDetail | undefined | null
): FormattedCartLines => {
  if (!cart || !cart.Lines) {
    return defaultFormattedCartLines;
  }

  return cart.Lines.reduce(
    (
      memo: FormattedCartLines,
      line: CartDetail["Lines"][number],
      index: number
    ) => {
      // Group into products or samples array
      const arrayKey = line.Type === PT.CORNERSAMPLE ? "samples" : "products";
      memo[arrayKey].push({
        ...line,
        cartIndex: index, // TODO - this may not be needed anymore
      });

      if (line.Type === PT.PHOTOFRAME || line.Type === PT.GALLERYFRAME) {
        memo[line.Type].isInCart = true;
        memo[line.Type].cumTotal += line.Amount;
      }

      return memo;
    },
    structuredClone(defaultFormattedCartLines)
  );
};
